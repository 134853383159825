import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import CoverImage from "./CoverImage";
import CoverOverlay from "./CoverOverlay";
import { WmkLink } from "wmk-link";
import styled from "styled-components";
import OffClick from "../../../../../components/common/OffClick";
import { colors } from "../../../../palette";
import { ContentfulDocumentsEmbedFields } from "../../../../../node/fragments/NodePageFields";

const Styled = {
  DocLink: styled(Col)`
    display: flex;
    justify-content: center;
    -webkit-flex: initial;
    a {
      font-weight: bold;
      text-decoration: underline;
      color: ${colors.hex("accent")};
      &:hover {
        color: ${colors.hex("secondary")};
      }
    }
    h6 {
      width: 75%;
      text-align: center;
    }
  `,

  Button: styled.div`
    button,
    a {
      width: 100%;
      background: ${colors.hex("secondary")};
      color: ${colors.hex("white")};
      padding: 0.5rem 2rem;
      border-radius: 0.5rem;
      border: none;
      text-decoration: none;
      font-weight: bold;
      &:hover {
        background: ${colors.hex("primary")};
      }
    }
  `,

  YearSection: styled.div`
    padding: 1rem 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .drop {
      position: relative;
      display: none;
      z-index: -10;
      top: -100;
      opacity: 0;
      transition: all 0.3s ease;
      margin-top: 1rem;
      &.open {
        z-index: 10;
        display: block;
        top: 0;
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
  `
};

const CurrWrap = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  }
`;

// const StyledOffClick = styled(OffClick)`
//   z-index: 0;
//   width: 50vw !important;
//   &:hover {
//     cursor: default;
//   }
// `

const DocEmbed = ({ fields }: { fields: ContentfulDocumentsEmbedFields }) => {
  const [currDrop, setCurrDrop] = useState<number>();
  const updateCurrDrop = (dropNum: number) => {
    (currDrop === dropNum) === null ? setCurrDrop(null) : setCurrDrop(dropNum);
  };

  const { title, abbreviation, documents } = fields;

  // get array of unique years
  let yearsArr: string[] = [];
  Array.isArray(documents)
    ? documents.map((doc, i) => {
        const __title = doc.title?.split(" ");
        const year = __title[__title.length - 1].replace(/_/g, " ");
        const yearNoMonth = year.split(" ")[1];
        return yearsArr.indexOf(yearNoMonth) === -1
          ? yearsArr.push(yearNoMonth)
          : null;
      })
    : [];
  //console.log("yearsArr: ", yearsArr);
  return documents.length ? (
    <>
      <Col
        style={{ display: "flex", justifyContent: "center", padding: "2vh" }}
        className="doc-col">
        <Row className="flex-column">
          <Col
            style={{
              display: "flex",
              WebkitFlex: "initial",
              flexShrink: 0,
              justifyContent: "center",
              position: "relative",
              paddingBottom: "2vh"
            }}>
            <WmkLink
              to={documents[0]?.file?.url}
              target="blank"
              label={title + " " + documents[0]?.title + " PDF"}>
              <CoverImage title={title} />
              <CoverOverlay title={abbreviation || title} />
            </WmkLink>
          </Col>
          <Styled.DocLink>
            <h6>{title}</h6>
          </Styled.DocLink>
          <CurrWrap>
            <Styled.Button>
              <WmkLink to={documents[0]?.file?.url} target="blank">
                Current
              </WmkLink>
            </Styled.Button>
          </CurrWrap>
        </Row>
      </Col>
      {currDrop !== null ? (
        <OffClick closeAction={() => updateCurrDrop(null)} />
      ) : null}
    </>
  ) : null;
};

export default DocEmbed;

/*
          {
            // map through array of unique years
            yearsArr.map((year, i) => {
              //console.log(i)
              return (
                <Styled.YearSection key={year + "sec" + i}>
                  {// render button for year that toggles dropdown for that year}
                  <Styled.Button>
                    <button onClick={() => updateCurrDrop(i)}>{year}</button>
                  </Styled.Button>
                  {// map through _documents }
                  <div className={currDrop === i ? "drop open" : "drop"}>
                    {documents.map((doc, j) => {
                      // console.log('doc: ', doc)
                      const { file, title } = doc;

                      const __title = title.split(" ");
                      const monthAndYear = __title[__title.length - 1].replace(
                        /_/g,
                        " "
                      );
                      const yearOnly = monthAndYear.split(" ")[1];
                      //const _year = year ? year : __title[__title.length - 1];
                      // if year of doc matches current year of map, render doc link
                      return year === yearOnly ? (
                        <Styled.DocLink key={abbreviation + j}>
                          <WmkLink
                            to={file.url}
                            target="blank"
                            label={title + " " + __title.join(" ") + " PDF"}>
                            {monthAndYear}
                          </WmkLink>
                        </Styled.DocLink>
                      ) : null;
                    })}
                  </div>
                </Styled.YearSection>
              );
            })
          }
          */
