import React from "react";
import { IoIosSearch } from "react-icons/io";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { SearchSliderOpen } from "wmk-search";
import { colors } from "../../../../vars/palette";

const InputWrap = styled.div`
  border: none;
  background: none;
  appearance: none;
  border-bottom: 2px solid ${colors.hex("white")};
  width: 12vw;
  height: 1.2rem;
  font-size: 0.8rem;
  color: ${colors.hex("white")};
`;

const SearchWrap = styled(Row)`
  .faux-input {
    background: none;
    appearance: none;
    border: none;
    color: ${colors.hex("white")};
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .search-icon {
    margin-left: 0.75vw;
    font-size: 1.1rem;
  }
`;

const Search = ({ isSearchOpen, setIsSearchOpen }) => {
  return (
    <SearchWrap
      style={{
        justifyContent: "flex-start",
        alignItems: "center",
        margin: 0,
        padding: "calc(1vh - 2px) 0 calc(1vh + 2px) 0",
      }}
      className={isSearchOpen ? "search-open" : "search-closed"}
    >
      <Row style={{ marginRight: ".75vw" }}>
        <SearchSliderOpen
          className="faux-input"
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
        >
          <Col
            sm="auto"
            style={{
              color: colors.hex("white"),
              padding: "0 .5vw 0 1vw",
              fontSize: ".8rem",
            }}
            className="search-box"
          >
            <IoIosSearch className="search-icon" />
          </Col>
          <Col className="search-box" style={{ padding: "0 .5vw 0 0" }}>
            <InputWrap />
          </Col>
        </SearchSliderOpen>
      </Row>
    </SearchWrap>
  );
};
export default Search;
