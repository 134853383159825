import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { WmkLink } from "wmk-link";
import {scrubPipeInTitle} from '../MainMenu/MainMenu'
import styled from "styled-components";
import { colors } from "../../../../vars/palette";
import { MenuContainerFields } from "../../../../node/fragments/NodeMenuContainerFields";
import { constructPath } from "../../../Blocks/BlockHash";
import { MenuItemFields } from "../../../../node/fragments/NodeMenuItemFields";

const DrawerMenuWrap = styled.div`
  .inner-drawer,
  .inner-inner-drawer {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 1.5rem;
  }
  a {
    color: ${colors.hex("white")};
  }
`;

const BurgerDrawerMenu = ({
  menu,
  isBurgerOpen,
  currBurgerDrop,
  updateCurrBurgerDrop,
  close
}: {
  menu: MenuContainerFields;
  isBurgerOpen: boolean;
  currBurgerDrop: string;
  updateCurrBurgerDrop: React.Dispatch<React.SetStateAction<string>>;
  close: () => void;
}) => {
  return <>{menu.menuItems.map((menuItem, i: number) => {
    const highestLevelSlug = "slug" in menuItem ? menuItem.slug : "";
    return "menuItems" in menuItem ? (
      <DrawerMenuWrap key={"drawer-outer-" + i + highestLevelSlug}>
        <button
          onClick={() => {
            updateCurrBurgerDrop(menuItem.title);
          }}>
          {scrubPipeInTitle(menuItem.title) + ` `}
          <FaAngleDown
            style={
              menuItem.title === currBurgerDrop
                ? {
                    transform: "rotate(180deg)",
                    transition: "transform .25s ease"
                  }
                : {
                    transform: "rotate(0deg)",
                    transition: "transform .25s ease"
                  }
            }
          />
        </button>
        <div
          className="inner-drawer"
          style={
            menuItem.title === currBurgerDrop && isBurgerOpen
              ? null
              : { display: "none" }
          }>
          {menuItem.menuItems.map((menuItem, j) => {
            // console.log(menuItem)
            const secondLevelSlug = menuItem.slug;
            return (
              <div key={"drawer-inner-" + j + secondLevelSlug}>
                <WmkLink
                  onClick={() => close()}
                  to={
                    secondLevelSlug
                      ? "/" + highestLevelSlug + "/" + secondLevelSlug
                      : constructPath(menuItem.entry)
                  }>
                  {" "}
                  {/* ======= FIX WITH CORRECT ROUTE ======== */}
                  {scrubPipeInTitle(menuItem.title)}
                </WmkLink>
                <div className="inner-inner-drawer">
                  {menuItem.__typename === "ContentfulMenuDropdownContainer"
                    ? menuItem.menuItems.map(
                        (menuItem: MenuItemFields, k: number) => {
                          const lowestLevelSlug =
                            menuItem.entry && menuItem.entry.slug;
                          return (
                            <WmkLink
                              onClick={() => close()}
                              to={
                                `/` +
                                highestLevelSlug +
                                `/` +
                                secondLevelSlug +
                                `/` +
                                lowestLevelSlug
                              }
                              key={"drawer-item-" + k}>
                              {/* ======= FIX WITH CORRECT ROUTE ======== */}
                              {scrubPipeInTitle(menuItem.title)}
                            </WmkLink>
                          );
                        }
                      )
                    : null}
                </div>
              </div>
            );
          })}
        </div>
      </DrawerMenuWrap>
    ) : menuItem.entry ? (
      <WmkLink
        to={constructPath(menuItem.entry)}
        key={"drawer-item-" + i}
        onClick={() => close()}>
        {scrubPipeInTitle(menuItem.entry.title)}
      </WmkLink>
    ) : null;
  })}</>;
};

export default BurgerDrawerMenu;
