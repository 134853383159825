import React from "react";
import styled from "styled-components";

const Styled = {
  OffClick: styled.button`
    position: fixed;
    top: 0;
    // left: 0;
    height: 100vh;
    appearance: none;
    border: none;
    background: none;
  `
};

const OffClick = ({
  closeAction //, half
}: {
  closeAction: (s: string) => void;
}) => {
  // const styleObject =
  //   half === "leftHalf"
  //     ? {width: '50vw', left: 0}
  //     : half === "rightHalf"
  //       ? {width: '50vw', left: '50vw'}
  //       : {width: '100vw', left: 0}
  return (
    <Styled.OffClick
      onClick={
        () => closeAction("") //() => closeAction("")
      }
      aria-label="close dropdown menu"
    />
  );
};

export default OffClick;
