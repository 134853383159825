import Color from "ts-color-class";

export const colors = {
  white: new Color("#fefefe"),
  black: new Color("#3C3C3C"),
  cyan: new Color("#25A2D7"),
  blue: new Color("#197FAA"),
  lightBlue: new Color("#A7D6F0"),
  lighterBlue: new Color("#a9ddfa"),
  green: new Color("#194E50"),
  army: new Color("#0D393B"),
  teal: new Color("#6b9596"),
  sky: new Color("#D3EBF8"),
  silver: new Color("#EDF7FC"),
  orange: new Color("#E59B5C")
};
