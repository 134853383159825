import { ColorPalette } from "wmk-color-palette";

export const colors = new ColorPalette([
  {
    value: "#25A2D8",
    group: "blue",
  },
  {
    value: "#fefefe",
    group: "white",
    reverse: true,
  },
  {
    value: "#3C3C3C",
    group: "black",
    text: true,
    black: true,
  },
  {
    value: "#25A2D7",
    group: "blue",
    secondary: true,
  },
  {
    value: "#197FAA",
    group: "blue",
    primary: true,
  },
  {
    value: "#A7D6F0",
    group: "blue",
    tertiary: true,
  },
  //   (lighterBlue: {
  //     hex: "#a9ddfa",
  //   }),
  {
    value: "#194E50",
    coaccent: true,
    group: "green",
  },
  //   (greenPointSixOp: {
  //     rgba: "rgba(25, 78, 80, 0.6)",
  //   }),
  //   (greenPointEightOp: {
  //     rgba: "rgba(25, 78, 80, 0.8)",
  //   }),
  //   (darkerGreen: {
  //     hex: "#144648",
  //   }),
  //   (darkestGreen: {
  //     hex: "#0D393B",
  //   }),
  //   (lightGreen: {
  //     hex: "#6b9596",
  //   }),
  //   (sky: {
  //     hex: "#D3EBF8",
  //   }),
  //   (silver: {
  //     hex: "#EDF7FC",
  //   }),
  {
    value: "#cccccc",
    group: "gray",
  },
  {
    value: "#E59B5C",
    accent: true,
    group: "orange",
  },
]);
