import * as React from "react";
import styled from "styled-components";
import { FaRegFilePdf } from "react-icons/fa";
import { WmkLink } from "wmk-link";
import { colors } from "../../../palette";
import { ContentfulDocumentsTableEmbedFields } from "../../../../node/fragments/NodePageFields";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0;
`;
const StyledTable = styled.table`
  tr:not(:first-of-type) {
    border-bottom: 2px solid ${colors.hex("tertiary")};
  }
  th {
    background: ${colors.hex("coaccent")};
    color: ${colors.hex("white")};
    text-transform: uppercase;
    padding: 2px 0;
  }
  td {
    padding: 0.75rem 0;
  }
  th,
  td {
    &:first-of-type {
      padding-left: 1rem;
      padding-right: 4rem;
      min-width: 20rem;
    }
    &:not(:first-of-type) {
      padding: 0 1rem;
      text-align: center;
    }
  }
  @media only screen and (max-width: 599px) {
    th,
    td {
      &:first-of-type {
        min-width: 0;
      }
    }
  }
  @media only screen and (max-width: 399px) {
    th,
    td {
      &:first-of-type {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
      &:not(:first-of-type) {
        padding: 0 0.25rem;
      }
    }
  }
`;

const DocumentsTableEmbed = ({
  table
}: {
  table: ContentfulDocumentsTableEmbedFields;
}) => {
  // console.log('fields: ', fields);
  const title = table.title; //fields.title["en-US"];
  const showTitle = table.showTitle; //fields.showTitle["en-US"];
  const tableHeaders = table.tableHeaders; //fields.tableHeaders["en-US"];
  const tableColumnCount = tableHeaders.length; //number of columns for the table is set by the tableHeaders, regardless of what data is included in the table rows

  const rows = table.rows; //fields.rows["en-US"];
  return (
    <Wrap>
      {showTitle && <h3>{title}</h3>}
      <StyledTable>
        <tr>
          {tableHeaders.map((head, i) => (
            <th key={`${head}-${i}`}>{head}</th>
          ))}
        </tr>
        {rows.map((row, i) => {
          const rowLabel = row.rowLabel;
          const documents = row.documents;
          return (
            <tr key={`${rowLabel}-${i}`}>
              <td>{rowLabel}</td>
              {documents.map((doc, i) => {
                const docUrl = doc.file.url;
                const docTitle = doc.title;
                return (
                  i < tableColumnCount - 1 && (
                    <td key={`${docTitle}-${i}`}>
                      {docTitle !== "Placeholder PDF" && (
                        <WmkLink to={docUrl} target="_blank">
                          <FaRegFilePdf
                            color={colors.hex("secondary")}
                            title={`download ${docTitle} pdf`}
                          />
                        </WmkLink>
                      )}
                    </td>
                  )
                );
              })}
            </tr>
          );
        })}
      </StyledTable>
    </Wrap>
  );
};

export default DocumentsTableEmbed;
