import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SprayerNozzleIcon from "../../icons/SprayerWaterMyYard";
import { colors } from "../../ui/colors";
import Button from "../../common/Button";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useLayoutContext } from "../../../context/LayoutContext";
import {
  ContentfulImageQuery,
  GatsbyImageData,
  Img,
  WmkImage
} from "wmk-image";
import { WmkLink } from "wmk-link";
import { globalHistory } from "@reach/router";

const AlertContainer = styled(Container)`
  background-image: url("https://images.ctfassets.net/v8jpyszhn40x/5GFQ3tj94LGvNkZ6eFasKa/8ea7840e55f85f5a4b75804ba3e4b587/top_banner_background.png?w=1400&h=78&q=50&fm=png");
  background-repeat: no-repeat;
  background-size: auto 190px;
  background-position: center;
  .inner-container {
    // padding-top: 10px;
    // padding-bottom: 10px;
    display: flex;
    justify-content: center;
  }
`;

const Styled = {
  Alert: styled(Row)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    @media screen and (max-width: 740px) {
      padding: 0.5rem 0;
    }
  `,
  Sprayer: styled(Col)`
    margin: 0 -1.75rem 0 2rem;
  `,
  Text: styled(Col)`
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    @media screen and (max-width: 740px) {
      font-size: 1.125rem;
    }
  `,
  Button: styled(Col)`
    padding: 0 2rem;
    a {
      @media screen and (max-width: 740px) {
        font-size: 0.8rem;
      }
    }
    span {
      @media screen and (max-width: 740px) {
        display: none;
      }
    }
    strong {
      @media screen and (min-width: 740px) {
        display: none;
      }
    }
  `
};

const Alert = ({
  setter,
  height,
  data,
  setPledgeIsClicked
}: {
  setter: (n: number) => void;
  height: number;
  data: {
    alertBannerBackground: ContentfulImageQuery;
    alertBannerText: ContentfulImageQuery;
    pledgeNowButton: ContentfulImageQuery;
    topBannerButton: ContentfulImageQuery;
  };
  setPledgeIsClicked: Function;
}) => {
  // const [location, setLocation] = useState("");

  const alertRef = useRef<HTMLDivElement>();
  const context = useLayoutContext();
  const bannerTextImage = new Img(data.alertBannerText);
  const defaultButtonImage = new Img(data.topBannerButton);
  const pledgeNowButton = new Img(data.pledgeNowButton);
  // console.log("alert data", data);
  useEffect(() => {
    const box = alertRef.current.getBoundingClientRect();
    setter(box.height);
  }, [height]);
  // useEffect(() => {
  //   setLocation(window?.location?.pathname);
  // }, []);
  // useEffect(() => {
  //   history.listen(dismissAllFlags);
  // }, []);
  // const getLocation = () => {
  //   return window?.location?.pathname;
  // };
  // useEffect(() => {
  //   return globalHistory.listen(({ action }) => {
  //     if (action === "PUSH") {
  //       setLocation(window?.location?.pathname);
  //     }
  //   });
  // }, []);
  return (
    <AlertContainer
      fluid
      style={
        {
          // backgroundImage: `url("https://images.ctfassets.net/v8jpyszhn40x/5GFQ3tj94LGvNkZ6eFasKa/8ea7840e55f85f5a4b75804ba3e4b587/top_banner_background.png?w=1400&h=78&q=50&fm=png")`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "auto 160px",
          // backgroundPosition: "center"
          // backgroundColor: colors.orange.getHex()
          // height: context.showAlert ? undefined : 0,
          // display: context.showAlert ? undefined : "none"
        }
      }
      ref={alertRef}>
      <Container className="inner-container" fluid>
        <Styled.Alert>
          <Col xs={"auto"}>
            <WmkImage image={bannerTextImage} />
          </Col>
          <Col xs={"auto"}>
            <WmkLink to={`/saving-water/save-outdoors/water-less-yall`}>
              <WmkImage image={defaultButtonImage} />
            </WmkLink>
            {/* {!(location && !location.includes("water-less-yall")) ? (
              <WmkLink
                to={`/saving-water/save-outdoors/water-less-yall-staging`}
                onClick={() => {
                  setPledgeIsClicked(true);
                }}>
                <WmkImage image={pledgeNowButton} />
              </WmkLink>
            ) : (
              <WmkLink
                to={`/saving-water/save-outdoors/water-less-yall-staging`}>
                <WmkImage image={defaultButtonImage} />
              </WmkLink>
            )} */}
          </Col>
          {/* <Styled.Sprayer xs="auto">
            <SprayerNozzleIcon height={40} color={"white"} />
          </Styled.Sprayer>
          <Styled.Text xs="auto">Water Less, Y'all</Styled.Text>
          <Styled.Button xs="auto">
            <Button to="/saving-water/save-outdoors/water-less-yall" white>
              <span>Click here to learn more</span>
              <strong>Learn more</strong>
            </Button>
          </Styled.Button> */}
        </Styled.Alert>
      </Container>
    </AlertContainer>
  );
};

export default Alert;
