/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import styled from "styled-components";
import { MainLayout, Sticky } from "wmk-layout";
import { useState, useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { colors } from "../../vars/palette";
import Alert from "./Header/Alert";
import { LayoutProvider, useLayoutContext } from "../../context/LayoutContext";
import { graphql, useStaticQuery } from "gatsby";
import Locked from "../common/locked";
import { globalHistory } from "@reach/router";

const GlobalStyles = styled.div`
  font-family: "Assistant", sans-serif;
  color: ${colors.hex("text")};
  font-size: 1.125rem;
  a,
  button {
    transition: all 0.3s ease;
    &:hover {
      transition: all 0.3s ease;
    }
  }
`;

const Layout = ({
  children
}: {
  children: React.ReactNode[] | React.ReactNode;
}) => {
  const [alertHeight, setAlertHeight] = useState(0);
  const [pledgeIsClicked, setPledgeIsClicked] = useState();
  const [location, setLocation] = useState("");
  const data = useStaticQuery(query);
  useEffect(() => {
    setLocation(window?.location?.pathname);
  }, []);
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setLocation(window?.location?.pathname);
      }
    });
  }, []);
  // console.log("data", data);
  return (
    <LayoutProvider>
      <GlobalStyles>
        <MainLayout
          Header={
            <>
              <Sticky
                absolute
                Alert={
                  location && !location.includes("water-less-yall") ? (
                    <Alert
                      setter={setAlertHeight}
                      height={alertHeight}
                      data={data}
                      setPledgeIsClicked={setPledgeIsClicked}
                    />
                  ) : (
                    <></>
                  )
                }>
                <Header />
              </Sticky>
              {location && !location.includes("water-less-yall") ? (
                <div style={{ marginBottom: alertHeight }} />
              ) : (
                <></>
              )}
            </>
          }
          Footer={<Footer />}>
          {children as React.ReactChild}
        </MainLayout>
      </GlobalStyles>
    </LayoutProvider>
  );
};

export default Layout;

const query = graphql`
  query {
    topBannerButton: contentfulAsset(
      title: {eq: "alert banner click to win button v3"}
    ) {
      gatsbyImageData(quality: 100)
      title
      file {
        url
        contentType
      }
      description
    }
    alertBannerText: contentfulAsset(
      filename: { eq: "top banner graphics.png" }
    ) {
      gatsbyImageData(quality: 100)
      title
      file {
        url
        contentType
      }
      description
    }
    alertBannerBackground: contentfulAsset(
      filename: { eq: "top banner background.png" }
    ) {
      gatsbyImageData
      title
      file {
        url
        contentType
      }
      description
    }
  }
`;
