import React, { MouseEventHandler } from "react";
import { LinkTarget, WmkLink } from "wmk-link";
import styled from "styled-components";
import { colors } from "../../vars/palette";

const ButtonWrap = styled.div<{ white?: boolean }>`
  a,
  button {
    text-decoration: none;
    border: none;
    background: ${({ white }) =>
      white ? colors.hex("white") : colors.hex("secondary")};
    color: ${({ white }) =>
      white ? colors.hex("primary") : colors.hex("white")};
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    font-weight: bold;
    appearance: none;
    //border: 2px solid white;
    display: block;
    &:hover {
      background: ${({ white }) =>
        white ? colors.hex("primary") : colors.hex("tertiary")};
      color: ${({ white }) =>
        white ? colors.hex("white") : colors.hex("primary")};
      box-shadow: 0px 0px 3px ${colors.rgba("black", 0.25)};
    }
    &:focus {
      @media only screen {
        outline: none;
      }
    }
  }
`;

const Button = ({
  children,
  action,
  to,
  target,
  style,
  white
}: {
  children: React.ReactNode;
  action?: MouseEventHandler<HTMLButtonElement>;
  to?: string;
  target?: LinkTarget;
  style?: React.CSSProperties;
  white?: boolean;
}) => {
  return (
    <ButtonWrap white={white}>
      {action ? (
        <button className="utrwd-btn" onClick={action}>
          {children}
        </button>
      ) : (
        <WmkLink
          className="utrwd-btn"
          to={to}
          target={target ? target : undefined}
          style={style ? style : undefined}>
          {children}
        </WmkLink>
      )}
    </ButtonWrap>
  );
};

export default Button;
