import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import TopMenu from "./TopMenu/TopMenu";
import MainMenu from "./MainMenu/MainMenu";
import Burger from "./Burger/Burger";
import BurgerDrawer from "./Burger/BurgerDrawer";
import { WmkLink as Link, WmkLink } from "wmk-link";
import { colors } from "../../../vars/palette";
import { colors as newColors } from "../../ui/colors";
import {
  MenuContainerFields,
  MenuDropdownContainerFields
} from "../../../node/fragments/NodeMenuContainerFields";
import { PageFields } from "../../../node/fragments/NodePageFields";
import { NewsFields } from "../../../node/fragments/NodeNewsFields";
import { SearchSliderDrawer } from "wmk-search";
import { MenuItemFields } from "../../../node/fragments/NodeMenuItemFields";
import { constructPath } from "../../Blocks/BlockHash";

const DrawerWrap = styled.div`
  .wmk-layout-sticky-header, .wmk-layout-wrap {
    overflow: hidden !important;
  }
  button.close {
    font-size: 4rem;
    color: ${colors.hex("white")};
    min-height: 4rem;
    background: none;
    border: none;
  }
  .search-form input {
      font-size: 3rem;
      color: ${colors.hover("tertiary", 0.1, "flip")};
      appearance: none;
      border: none;
      border-radius: 0;
      background: none;
      border-bottom: 2px solid ${colors.hex("tertiary")};
      &::placeholder {
          color: ${colors.hover("tertiary", 0.1, "flip")};
      }
      &:focus {
          background: none;
          color: ${colors.hex("white")}
          border-color: ${colors.hex("white")};
          box-shadow: none;
      }
      @media screen and (max-width: 740px){
          font-size: 1.5rem;
      }
  }
  .results {
    .flex-column {
      overflow: auto;}
      padding: 4vh;
      color: ${colors.hover("tertiary", 0.1, "flip")};
      font-size: 2rem;
      @media screen and (max-width: 740px){
        font-size: 1rem;
        }
        .wmk-link {
            color: ${colors.hover("tertiary", 0.1, "flip")};
            &:hover {
                color: ${colors.hover("tertiary")};
            }
        }
  }
`;

const Wrap = styled.div`
  clip-path: inset(-100vw -100vw -100vw 0);
  position: relative;
  z-index: 50;
  display: flex;
  flex-wrap: nowrap;
  color: black;
  position: relative;
  .logo-wrap {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 5rem;
    width: 35%;
    p {
      position: relative;
      right: -5%;
      z-index: 10;
      color: ${colors.hex("white")};
      white-space: nowrap;
      margin-bottom: 0;
      &.logo-line-1 {
        font-size: 2rem;
        font-weight: 900;
        letter-spacing: 2px;
        color: ${colors.hex("primary")};
        transition: all 0.5s ease;
      }
      &.logo-line-2 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: -10px;
        color: ${colors.hex("secondary")};
        transition: all 0.5s ease;
      }
    }
    .gatsby-image-wrapper {
      height: 5rem;
    }
    .logo-background-wrap {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      width: 115%;
      height: 100%;
      background: ${colors.hex("white")};
      clip-path: polygon(0 0, 0 100%, 100% 100%, 90% 0);
      display: flex;
      transition: all 0.5s ease;
      .logo-accent-1 {
        position: relative;
        left: -10%;
        width: 20%;
        height: 100%;
        background: ${newColors.sky.getHex()};
        transform: skewX(-30deg);
        transition: all 0.5s ease;
      }
      .logo-accent-2 {
        position: relative;
        left: -10%;
        width: 15%;
        height: 100%;
        background: ${newColors.silver.getHex()};
        transform: skewX(-30deg);
        transition: all 0.5s ease;
      }
    }
  }
  .menus-wrap {
    width: 65%;
    position: relative;
    transition: all 0.5s ease;
  }
  @media only screen and (max-width: 1200px) {
    .logo-wrap {
      width: 75%;
      padding-right: 10%;
    }
    .menus-wrap {
      width: 25%;
      background: ${newColors.green.getHex()};
      .main-menu-wrap {
        background: none;
      }
      padding: 1.5rem;
    }
  }
  @media only screen and (max-width: 750px) {
    .logo-wrap {
      padding-right: 3%;
    }
  }
  @media only screen and (max-width: 500px) {
    .logo-wrap {
      padding-right: 0%;
    }
    .menus-wrap {
      padding: 1.5rem 3%;
    }
  }

  // ======= header styling when hamburger is open ======= //
  &.thin {
    .logo-line-1,
    .logo-line-2 {
      color: ${colors.hex("white")} !important;
      transition: all 0.5s ease;
    }
    .logo-background-wrap {
      background: ${colors.hex("coaccent")};
      transition: all 0.5s ease;
      .logo-accent-1 {
        background: ${newColors.army.getHex()};
        transition: all 0.5s ease;
      }
      .logo-accent-2 {
        background: ${newColors.green.getHex()};
        transition: all 0.5s ease;
      }
    }
    .menus-wrap {
      background: ${colors.hex("tertiary")};
      transition: all 0.5s ease;
    }
  }
`;

const StyledResult = styled.div`
  border-bottom: 1px solid ${newColors.white.alpha(0.5).toString()};

  a {
    padding: 0.5rem 0 0.75rem 0;
    display: block;
    line-height: 1;
    color: ${newColors.white.getHex()};
    text-decoration: none;
    &:hover {
      color: ${newColors.orange.getHex()};
    }
  }
`;

const Header = ({ style }: { style?: React.CSSProperties }) => {
  const [currDrop, setCurrDrop] = useState("");
  const [showBurger, setShowBurger] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [currBurgerDrop, setCurrBurgerDrop] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    //bad variable naming goes down
    let x = document.getElementsByClassName('wmk-layout-wrap');
    x[0].style['overflow-x'] = 'hidden';
    let y = document.getElementsByClassName('wmk-layout-sticky-header');
    y[0].style['overflow-x'] = 'hidden';
    let z = document.getElementsByTagName('header');
    z[0].style['height'] = '260px';
    return () => window.removeEventListener("resize", handleResize);
  }, [showBurger, isBurgerOpen]);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 1200) {
      setShowBurger(true);
    } else {
      setShowBurger(false);
      setIsBurgerOpen(false);
    }
  };

  const handleClose = () => {
    setIsBurgerOpen(false);
    setCurrBurgerDrop("");
  };

  const updateCurrDrop = (clickedDropTitle: string, offClick?: boolean) => {
    currDrop === clickedDropTitle || offClick
      ? setCurrDrop("")
      : setCurrDrop(clickedDropTitle);
  };

  const data: MenuQuery = useStaticQuery(query);
  const { mainMenu, topMenu, pages, posts } = data;
  return (
    <>
      <Wrap className={isBurgerOpen ? "thin" : ""} style={style}>
        <div className="logo-wrap">
          <Link
            to="/"
            style={{
              color: `black`,
              textDecoration: `none`
            }}>
            <p className="logo-line-1">Upper Trinity</p>
            <p className="logo-line-2">Regional Water District</p>
          </Link>
          <div className="logo-background-wrap">
            <div className="logo-accent-1" />
            <div className="logo-accent-2" />
          </div>
        </div>
        <div className="menus-wrap">
          {showBurger ? (
            <>
              <Burger
                isBurgerOpen={isBurgerOpen}
                setIsBurgerOpen={setIsBurgerOpen}
                setCurrBurgerDrop={setCurrBurgerDrop}
              />
            </>
          ) : (
            <>
              <TopMenu
                data={topMenu}
                currDrop={currDrop}
                updateCurrDrop={updateCurrDrop}
                setIsSearchOpen={setIsSearchOpen}
                isSearchOpen={isSearchOpen}
              />
              <MainMenu
                data={
                  mainMenu.menuItems as (
                    | MenuItemFields
                    | MenuDropdownContainerFields
                  )[]
                }
                currDrop={currDrop}
                updateCurrDrop={updateCurrDrop}
              />
            </>
          )}
        </div>
      </Wrap>
      <BurgerDrawer
        isBurgerOpen={isBurgerOpen}
        menus={data}
        currBurgerDrop={currBurgerDrop}
        setCurrBurgerDrop={setCurrBurgerDrop}
        setIsSearchOpen={setIsSearchOpen}
        close={handleClose}
      />
      <DrawerWrap
        style={{
          minHeight: isSearchOpen ? "100vh" : "1px",
          height: isSearchOpen ? "auto" : "1px",
          width: isSearchOpen ? "100vw" : "1px",
          overflow: isSearchOpen ? "scroll" : "hidden",
          zIndex: isSearchOpen ? 500000 : -1
        }}>
        <SearchSliderDrawer
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
          query={{ posts, pages }}
          style={{
            backgroundColor: isSearchOpen ? colors.hex("primary") : "none"
          }}
          Result={({ result }) => {
            return (
              <StyledResult>
                <WmkLink to={result.to} target={result.target}>
                  {result.text}
                </WmkLink>
              </StyledResult>
            );
          }}
          resultConversion={(results: {
            nodeType: "pages" | "news";
            node: PageFields | NewsFields;
          }) => {
            return results.node.__typename === "ContentfulNews"
              ? { to: `/news/${results.node.slug}`, text: results.node.title }
              : { to: constructPath(results.node), text: results.node.title };
          }}
          // useAlgorithm={(event, setSearchKey, index, setSearchResults) => {
          //   let search = index.filter((item) => {
          //     const checkBlock = (block, check) => {
          //       let hasOne = false;
          //       block &&
          //         block.length &&
          //         block.forEach((b) => {
          //           const blower = b.title.toLowerCase();
          //           hasOne = blower.includes(check)
          //             ? blower.includes(check)
          //             : hasOne;
          //         });
          //       //console.log(hasOne);
          //       return hasOne;
          //     };
          //     const { blockMain } = item;
          //     const lower = item.title.toLowerCase();
          //     const target = event.target.value.toLowerCase();
          //     //console.log(blockMain)
          //     //checkBlock(blockMain, target);
          //     // console.log(
          //     //   lower,
          //     //   lower.includes(target),
          //     //   checkBlock(blockMain, target)
          //     // );
          //     return lower.includes(target) || checkBlock(blockMain, target);
          //   });
          //   return { key: event.target.value, results: search };
          // }}
        />
      </DrawerWrap>
    </>
  );
};

export default Header;

export interface MenuQuery {
  topMenu: MenuContainerFields;
  mainMenu: MenuContainerFields;
  pages: {
    edges: { node: PageFields }[];
  };
  posts: {
    edges: { node: NewsFields }[];
  };
}

const query = graphql`
  {
    topMenu: contentfulMenuContainer(title: { eq: "Top Menu" }) {
      ...NodeMenuContainerFields
    }
    mainMenu: contentfulMenuContainer(title: { eq: "Main Menu" }) {
      ...NodeMenuContainerFields
    }
    pages: allContentfulPages {
      edges {
        node {
          __typename
          title
          slug
          parentPageSlug
          subParentPageSlug
          ...BuilderBlockFields
        }
      }
    }
    posts: allContentfulNews(filter: { visibility: { in: "UTRWD" } }) {
      edges {
        node {
          __typename
          title
          slug
        }
      }
    }
  }
`;
