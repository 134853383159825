import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import Dropdown from "./MainMenuDropdown";
import { MenuDropdownContainerFields } from "../../../../node/fragments/NodeMenuContainerFields";
import { MenuItemFields } from "../../../../node/fragments/NodeMenuItemFields";
import { slugifyTitle } from "wmk-tag";
import { colors } from "../../../ui/colors";

const Wrap = styled.div`
  height: 3rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  .menu-item-wrap,
  .menu-dropdown-wrap {
    padding: 0 1rem;
    a {
      color: ${colors.white.getHex()};
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const scrubPipeInTitle = (title: string) => {
  const hasPipe = title.indexOf("|") !== -1;
  return hasPipe ? title.split(" | ")[0] : title;
};

export const scrubPipeInSlug = (slug: string) => {
  const pipeLoc = slug.indexOf("-or-");
  return pipeLoc === -1 ? slug : slug.slice(0, pipeLoc);
};

const MainMenu = ({
  data,
  currDrop,
  updateCurrDrop
}: {
  data: (MenuDropdownContainerFields | MenuItemFields)[];
  currDrop: string;
  updateCurrDrop: (s: string) => void;
}) => {
  // console.log("data from MainMenu.js: ", data);
  return (
    <Wrap className="main-menu-wrap">
      {data.map((menuItem, i) => {
        const titleSlug = slugifyTitle(menuItem.title);
        return menuItem.__typename === "ContentfulMenuItem" ? (
          <div className="menu-item-wrap" key={menuItem.title + i}>
            <WmkLink
              to={`/` + menuItem.entry.slug}
              onClick={() => updateCurrDrop("")}>
              {scrubPipeInTitle(menuItem.title)}
            </WmkLink>
          </div>
        ) : (
          <div className="menu-dropdown-wrap" key={menuItem.title + i}>
            <Dropdown
              menuItem={menuItem}
              dropdownTitleSlug={titleSlug}
              currDrop={currDrop}
              updateCurrDrop={updateCurrDrop}
            />
          </div>
        );
      })}
    </Wrap>
  );
};

export default MainMenu;
