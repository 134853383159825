import React, {
  ReactNode,
  useState,
  createContext,
  useContext
  //useEffect
} from "react";

export interface LayoutContextData {
  showAlert: boolean;
  hideAlert: () => void;
  unhideAlert: () => void;
}

const LayoutContext = createContext<LayoutContextData | undefined>(undefined);

const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [showAlert, setShowAlert] = useState(true);
  const hideAlert = () => {
    return setShowAlert(false);
  };
  const unhideAlert = () => {
    return setShowAlert(true);
  };
  return (
    <LayoutContext.Provider value={{ showAlert, hideAlert, unhideAlert }}>
      {children}
    </LayoutContext.Provider>
  );
};

const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useHubUserState must be used within a HubUserProvider");
  }
  return context;
};

export { useLayoutContext, LayoutContext, LayoutProvider };
