import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import BurgerDrawerMenu from "./BurgerDrawerMenu";
import { IoIosSearch } from "react-icons/io";
import { colors } from "../../../../vars/palette";
import { MenuContainerFields } from "../../../../node/fragments/NodeMenuContainerFields";

const Wrap = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  right: 0;
  position: absolute;
  z-index: 10000;
  background: ${colors.hex("coaccent")};
  &.burger-closed {
    top: 5rem;
    right: -100%;
    opacity: 0;
    transition: opacity 0.75s ease;
    height: 1px;
    width: 100%;
    overflow: hidden;
    & > * {
      display: none;
    }
  }
  &.burger-open {
    right: 0;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 5rem);
    top: 5rem;
    opacity: 1;
    transition: all 0.5s ease;
    overflow: auto;
  }
  a {
    text-transform: uppercase;
    color: ${colors.hex("white")};
    padding: 0 0.5rem;
    font-weight: bold;
    text-decoration: none;
  }
  button {
    text-align: left;
    background: none;
    border: none;
    color: ${colors.hex("white")};
    font-weight: bold;
    text-transform: uppercase;
    @media only screen {
      :focus {
        outline: none;
      }
    }
  }
`;

const BurgerDrawer = ({
  isBurgerOpen,
  currBurgerDrop,
  setCurrBurgerDrop,
  menus,
  setIsSearchOpen,
  close
}: {
  isBurgerOpen?: boolean;
  currBurgerDrop: string;
  setCurrBurgerDrop: React.Dispatch<React.SetStateAction<string>>;
  menus: { mainMenu: MenuContainerFields; topMenu: MenuContainerFields };
  setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
}) => {
  const { topMenu, mainMenu } = menus;
  const handleSearch = () => {
    setIsSearchOpen(true);
  };
  return (
    <Wrap className={isBurgerOpen ? "burger-open" : "burger-closed"}>
      <WmkLink onClick={() => close()} to={`/`}>
        HOME
      </WmkLink>
      <BurgerDrawerMenu
        menu={mainMenu}
        isBurgerOpen={isBurgerOpen}
        currBurgerDrop={currBurgerDrop}
        updateCurrBurgerDrop={setCurrBurgerDrop}
        close={close}
      />
      <div
        style={{
          borderBottom: "1px solid #cccccc",
          margin: "1rem 0",
          opacity: 0.25
        }}
      />
      <BurgerDrawerMenu
        menu={topMenu}
        isBurgerOpen={isBurgerOpen}
        currBurgerDrop={currBurgerDrop}
        updateCurrBurgerDrop={setCurrBurgerDrop}
        close={close}
      />
      <div
        style={{
          borderBottom: "1px solid #cccccc",
          margin: "1rem 0",
          opacity: 0.25
        }}
      />
      <button
        style={{ display: "flex", alignItems: "center" }}
        onClick={handleSearch}>
        <IoIosSearch /> Search
      </button>
    </Wrap>
  );
};

export default BurgerDrawer;
