import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Theme, { buildSlug } from "../../../vars/ThemeOptions";
import Button from "../../common/Button";
import { WmkLink } from "wmk-link";
import { Img, WmkImage } from "wmk-image";
import { GlobalFields } from "../../../node/fragments/NodeGlobalFields";
import { SocialMediaIcons } from 'wmk-social'

const Wrap = styled.footer`
  width: 100%;
  position: relative;
  padding: 1rem;
  .content-wrap {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    .row {
      height: 100%;
      align-items: center;
      .content-col {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.logo a {
          width: 50%;
          height: 50%;
          .gatsby-image-wrapper {
            width: 100%;
            height: 100%;
          }
        }
        &.contact {
          align-items: flex-start;
          .address {
            margin-bottom: 1rem;
          }
          .p {
            color: ${Theme.color.primary.hex};
            margin: 0;
            font-size: 0.85rem;
            white-space: nowrap;
            a {
              color: ${Theme.color.primary.hex};
            }
            &.bold {
              font-weight: bold;
              @media only screen and (max-width: 555px) {
                white-space: normal;
              }
            }
          }
          &:before {
            content: "";
            width: 2px;
            height: 50%;
            background: ${Theme.color.primary.hex};
            position: absolute;
            left: 0;
            top: 25%;
          }
          @media screen and (max-width: ${Theme.break.sm}px) {
            justify-content: center;
            &:before {
              display: none;
            }
            .contact-details-row {
              width: 100%;
            }
          }
        }
        &.newsletter-signup {
          .wmk-link {
            margin: 3vh 0;
            display: block;
          }
        }
      }
    }
    .social-col {
      display: flex;
      flex-direction: row !important;
      flex-wrap: nowrap;
      svg {
        margin: 0 1rem;
        fill: ${Theme.hex("lightBlue")};
      }
      .twitter {
        svg {
          width: 2rem !important;
          height: 1.8rem !important;
        }
      }
      .facebook {
        svg {
          height: 2rem !important;
        }
      }
      .instagram {
        svg {
          height: 1.8rem !important;
          width: 2rem !important;
        }
      }
      .youtube {
        svg {
          height: 2rem !important;
          width: 2rem !important;
        }
      }
    }
  }
  .bg-wrap {
    position: absolute;
    z-index: 0;
    left: -25vw;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .background-1 {
      width: 60%;
      height: 100%;
      background: ${Theme.color.sky.hex};
      transform: skewX(-30deg);
    }
    .background-2 {
      width: 40%;
      height: 100%;
      background: ${Theme.color.silver.hex};
      transform: skewX(-30deg);
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const UTRWDFooter = () => {
  const {
    options,
    privacy
  }: {
    options: GlobalFields;
    privacy: { title: string; slug: string };
  } = useStaticQuery(graphql`
    query {
      options: contentfulGlobal(title: { eq: "UTRWD Footer Options" }) {
        ...NodeGlobalFields
      }
      privacy: contentfulPages(title: { eq: "Privacy Policy" }) {
        title
        slug
      }
    }
  `);
  const { logo, button, phoneNumber, email } = options;

  return (
    <Wrap>
      <Container fluid className="content-wrap">
        <Row>
          <Col
            xs={{ span: 6, order: 1 }}
            lg={{ span: 3, order: 1 }}
            className="content-col logo"
            style={{ margin: "auto" }}>
            <WmkImage image={new Img(logo)} style={{ height: `140px` }} />
          </Col>
          <Col
            xs={{ span: 12, order: 3 }}
            lg={{ span: 3, order: 2 }}
            className="content-col newsletter-signup"
            style={{ margin: "auto" }}>
            <Button to={button.linkUrl} target={button.target}>
              {button.title}
            </Button>
          </Col>
          <Col
            xs={{ span: 12, order: 4 }}
            lg={{ span: 3, order: 3 }}
            className="content-col contact"
            style={{ margin: "auto" }}>
            <Row className="contact-details-row">
              <Col xs="auto" md={12} style={{ margin: "auto" }}>
                <div className="bold utrwd p">
                  Upper Trinity Regional Water District
                </div>
                <div className="address">
                  <div className="p">{options.addressLine1}</div>
                  <div className="p">{options.addressLine2}</div>
                  <div className="p">{options.addressLine3}</div>
                  <div className="p">{options.addressLine4}</div>
                </div>
              </Col>
              <Col xs="auto" md={12} style={{ margin: "auto" }}>
                <div className="p">
                  <WmkLink tel>{phoneNumber}</WmkLink>
                </div>
                <div className="p">
                  <WmkLink mailto>{email}</WmkLink>
                </div>
                <div className="p">
                  <WmkLink to={buildSlug(privacy)}>{privacy.title}</WmkLink>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 6, order: 2 }}
            lg={{ span: 3, order: 4 }}
            className="content-col social-col">
            <SocialMediaIcons socials={options.socialLinks.map(s=>{return {title: s.title.toLocaleLowerCase(), url: s.url }})} />
          </Col>
        </Row>
      </Container>
      <div className="bg-wrap">
        <div className="background-1" />
        <div className="background-2" />
      </div>
    </Wrap>
  );
};

export default UTRWDFooter;
