import React from "react";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { graphql, useStaticQuery } from "gatsby";

const CoverImage = ({ title }: { title: string }) => {
  const data: { file: ContentfulImageQuery } = useStaticQuery(query);
  const image = data.file;
  //console.log(image);
  return (
    // <WmkImage
    //   image={new Img({ gatsbyImageData: image, title, file: undefined })}
    // />
    <WmkImage image={new Img(image)} />
  );
};
export default CoverImage;

const query = graphql`
  {
    file: contentfulAsset(title: { eq: "PDF Cover Image" }) {
      file {
        url
        contentType
      }
      title
      gatsbyImageData(width: 162, height: 230)
    }
  }
`;
