import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import OffClick from "../../../common/OffClick";
import { WmkLink } from "wmk-link";
import { slugifyTitle as slugFromTitle } from "wmk-tag";
import { colors } from "../../../ui/colors";
import { MenuDropdownContainerFields } from "../../../../node/fragments/NodeMenuContainerFields";
import { MenuItemFields } from "../../../../node/fragments/NodeMenuItemFields";
import { PageFields } from "../../../../node/fragments/NodePageFields";
import { constructPath } from "../../../Blocks/BlockHash";
import { scrubPipeInTitle } from "./MainMenu";

const Styled = {
  DrawerItem: styled.div<{ alt?: "true" | "false" }>`
    padding: 5px 0 0 5px;
    a {
      position: relative;
      z-index: -100;
      font-weight: 100 !important;
      text-decoration: none;
      ${({ alt }) =>
        alt === "true"
          ? `background-color: ${colors.orange.getHex()}; padding: 2px 3px;`
          : ""}
    }
    .col-title {
      a {
        text-decoration: none;
      }
      padding: 0 0 5px 0;
    }
  `
};

const Wrap = styled.div`
  position: relative;
  .drop-title {
    position: relative;
    z-index: 100;
    a {
      color: ${colors.white.getHex()};
      text-decoration: none;
    }
    button {
      color: ${colors.white.getHex()};
      background: rgba(0, 0, 0, 0);
      border: none;
      padding: 0 5px;
      font-weight: bold;
      white-space: nowrap;
      transition: transform 0.5s ease;
      @media only screen {
        &:focus {
          outline: none;
        }
      }
    }
  }
  .drawer {
    right: 0;
    background: ${colors.green.alpha(0.8).toString()};
    position: absolute;
    z-index: -100;
    top: -20rem;
    opacity: 0;
    transition: all 0.3s ease;
    display: flex;
    font-size: 0.7rem;
    white-space: nowrap;
    padding: 5px 10px 10px 10px;
    .drawer-col {
      padding-right: 10px;
    }
  }
  .flexCol {
    flex-direction: column;
    div {
      padding: 0 0 5px 0;
    }
    a {
      text-decoration: none;
    }
  }
`;

const ArrowDown = styled(FaAngleDown)`
  color: ${colors.lightBlue.toString()};
  // transform: rotate(0deg);
  // transition: transform .5s ease;
`;

const TopMenuDropdown = ({
  menuItem,
  dropdownTitleSlug,
  currDrop,
  updateCurrDrop
}: {
  menuItem: MenuDropdownContainerFields;
  dropdownTitleSlug: string;
  currDrop: string;
  updateCurrDrop: (s: string) => void;
}) => {
  // console.log("menuItem from MainMenuDropdown.js: ", menuItem);

  const [dropOpen, setDropOpen] = useState(false);
  const toggleDrop = (menuItemTitle: string) => {
    updateCurrDrop(menuItemTitle);
  };
  useEffect(() => {
    currDrop === menuItem.title ? setDropOpen(true) : setDropOpen(false);
  }, [currDrop, menuItem.title]);

  return (
    <Wrap>
      <div className="drop-title">
        <button onClick={() => toggleDrop(menuItem.title)}>
          {scrubPipeInTitle(menuItem.title) + ` `}
          <ArrowDown
            style={
              dropOpen
                ? {
                    transform: "rotate(180deg)",
                    transition: "transform .5s ease"
                  }
                : {
                    transform: "rotate(0deg)",
                    transition: "transform .5s ease"
                  }
            }
          />
        </button>
      </div>
      <div
        className={
          `drawer ` + (menuItem.title === "Protecting Water" ? `flexCol` : ``)
        }
        style={dropOpen ? { zIndex: 10, top: "2rem", opacity: 1, right: 0 } : null}>
        {menuItem.menuItems.map((item, i: number) => {
          const titleSlug = slugFromTitle(item.title);
          const to =
            item.__typename === "ContentfulMenuItem"
              ? menuItem.title === "Protecting Water"
                ? `/protecting-water/` + item.entry.slug
                : `/` + item.entry.slug
              : `/` + dropdownTitleSlug + `/` + titleSlug;
          return item.__typename === "ContentfulMenuItem" ? (
            <div key={item.title + i}>
              <WmkLink
                onClick={() => updateCurrDrop("")}
                to={to}
                style={dropOpen ? { zIndex: 10 } : null}>
                {scrubPipeInTitle(item.title)}
              </WmkLink>
            </div>
          ) : (
            <div className="drawer-col" key={item.title + i}>
              <div className="col-title">
                <WmkLink
                  onClick={() => updateCurrDrop("")}
                  to={to}
                  style={dropOpen ? { zIndex: 10 } : null}>
                  {scrubPipeInTitle(item.title) + ` `}
                  <FaAngleDown />
                </WmkLink>
              </div>
              {item.menuItems.map(
                (innerItem: MenuItemFields | PageFields, j: number) => {
                  const slug =
                    innerItem.__typename === "ContentfulMenuItem" &&
                    innerItem?.entry?.slug;
                  const to =
                    innerItem.__typename === "ContentfulPages"
                      ? constructPath(innerItem)
                      : slug
                      ? `/` + dropdownTitleSlug + `/` + titleSlug + `/` + slug
                      : innerItem.url;
                  return (
                    <Styled.DrawerItem
                      key={innerItem.title + j}
                      alt={
                        Array.isArray(innerItem.title.match(/less/i))
                          ? "true"
                          : "false"
                      }>
                      <WmkLink
                        onClick={() => updateCurrDrop("")}
                        to={to}
                        style={dropOpen ? { zIndex: 10 } : null}
                        target={
                          innerItem.__typename === "ContentfulMenuItem"
                            ? innerItem.target === "blank"
                              ? "blank"
                              : undefined
                            : undefined
                        }>
                        {innerItem.title}
                      </WmkLink>
                    </Styled.DrawerItem>
                  );
                }
              )}
            </div>
          );
        })}
      </div>
      {currDrop !== "" && <OffClick closeAction={toggleDrop} />}
    </Wrap>
  );
};

export default TopMenuDropdown;
