import React from "react";
import { colors } from "../../../../../vars/palette";

const BulletDrop = ({ reverse }: { reverse?: boolean }) => {
  const fillColor = reverse ? colors.hex("white") : colors.hex("accent");
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.219"
      height="19.603"
      viewBox="0 0 13.219 19.603">
      <g
        //id="Path_646"
        //data-name="Path 646"
        transform="translate(0 13.592)"
        fill="none">
        <path
          d="M6.609-13.592s6.609,10.2,6.609,13.545A6.352,6.352,0,0,1,6.609,6.011,6.352,6.352,0,0,1,0-.047C0-3.393,6.609-13.592,6.609-13.592Z"
          stroke="none"
        />
        <path
          d="M 6.609344959259033 -7.819946765899658 C 6.402875423431396 -7.462238311767578 6.192581653594971 -7.092638969421387 5.982234954833984 -6.716059684753418 C 3.137252807617188 -1.622718811035156 3.005631446838379 -0.1373786926269531 3.000005722045898 -0.03991794586181641 C 3.004543304443359 1.643350601196289 4.621949195861816 3.011500358581543 6.609375 3.011500358581543 C 8.59679126739502 3.011500358581543 10.21419715881348 1.643350601196289 10.21873378753662 -0.03991794586181641 C 10.21311569213867 -0.1372146606445312 10.08189392089844 -1.617612361907959 7.251365184783936 -6.689449787139893 C 7.036237716674805 -7.074920177459717 6.820812702178955 -7.453625679016113 6.609344959259033 -7.819946765899658 M 6.609375 -13.59179973602295 C 6.609375 -13.59179973602295 13.21874523162842 -3.393159866333008 13.21874523162842 -0.04709959030151367 C 13.21874523162842 3.298970222473145 10.25963497161865 6.011500358581543 6.609375 6.011500358581543 C 2.959115028381348 6.011500358581543 -4.76837158203125e-06 3.298970222473145 -4.76837158203125e-06 -0.04709959030151367 C -4.76837158203125e-06 -3.393159866333008 6.609375 -13.59179973602295 6.609375 -13.59179973602295 Z"
          stroke="none"
          fill={fillColor}
          className="bullet-drop-stroke"
        />
      </g>
    </svg>
  );
};

export default BulletDrop;
