import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import * as React from "react";
// import { Fade } from "react-awesome-reveal";
import { WmkImage, Img, ContentfulImageQuery } from "wmk-image";
import ReactPlayer from "react-player";
// import { Typography } from "../ui/Typography";
import {
  handleRichTextChildren,
  RichTextBlock,
  RichTextNode
} from "wmk-rich-text";
import { BlockButton, blockHash, constructPath } from "./BlockHash";
import BulletDrop from "../flexible/blocks/BlockBody/Bullets/BulletDrop";
import { PageFields } from "../../node/fragments/NodePageFields";
import { colors } from "../ui/colors";
import { AssetFields } from "../../node/fragments/NodeNewsFields";
import {
  blocksEmbeddedEntry,
  blocksEmbeddedAsset,
  inlinesAssetHyperlink,
  inlinesHyperlink,
  inlinesEntryHyperlink,
  RichTextChildren,
  blocksTypography,
  blocksList,
  getRichTextOptions
} from "wmk-rich-text";
import { Typography } from "../ui/Typography";
import styled from "styled-components";

const NullComp = () => <></>;

const AssetComp = ({
  asset,
  contentType
}: {
  asset: ContentfulImageQuery;
  contentType: string;
}) => {
  const url = asset.file.url;
  return !contentType ? (
    <NullComp />
  ) : contentType.match(`image`) ? (
    <figure
    // style={{
    //   border: image.description
    //     ? `1px solid ${colors.cyan.toString()}`
    //     : undefined
    // }}
    >
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex"
        }}>
        <WmkImage
          image={new Img({ ...asset })}
          style={{ margin: "0 0 2vh 0" }}
        />
      </div>
      {asset.description ? (
        <figcaption
          style={{
            textAlign: "center",
            paddingBottom: `.5rem`,
            fontSize: `.9rem`,
            color: colors.blue.toString()
          }}>
          {asset.description}
        </figcaption>
      ) : null}
    </figure>
  ) : contentType.match(`video`) && url ? (
    <ReactPlayer url={url} controls />
  ) : (
    <BlockButton
      __typename="ContentfulMenuItem"
      title={asset.title as string}
      asset={asset as ContentfulImageQuery}
      entry={null}
      url={null}
    />
  );
};

export const options = getRichTextOptions({
  renderMark: {
    [MARKS.CODE]: (node: React.ReactNode) => {
      return <div className="code">{node}</div>;
    }
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: RichTextNode) =>
      blocksEmbeddedAsset(node, [], AssetComp),
    [BLOCKS.PARAGRAPH]: blocksTypography,
    [BLOCKS.EMBEDDED_ENTRY]: (node: RichTextNode) =>
      blocksEmbeddedEntry(node, [], blockHash),
    [BLOCKS.HEADING_1]: blocksTypography,
    [BLOCKS.HEADING_2]: (node: RichTextNode, children: RichTextChildren) =>
      blocksTypography(node, children, {
        Component: ({ children }) => (
          <Typography.H2 style={{ textAlign: "center" }}>
            {handleRichTextChildren(children)}
          </Typography.H2>
        )
      }),
    [BLOCKS.HEADING_3]: blocksTypography,
    [BLOCKS.HEADING_4]: blocksTypography,
    [BLOCKS.HEADING_5]: blocksTypography,
    [BLOCKS.HEADING_6]: blocksTypography,
    [BLOCKS.UL_LIST]: (node: RichTextBlock, children: RichTextChildren) =>
      blocksList(node, children, {
        Bullet: <BulletDrop />,
        textStyle: {
          display: "flex",
          alignItems: "center"
        }
      }),
    [INLINES.HYPERLINK]: inlinesHyperlink,
    [INLINES.ENTRY_HYPERLINK]: (
      node: RichTextNode,
      children: RichTextChildren
    ) =>
      inlinesEntryHyperlink(
        node,
        children,
        (ref: { __typename: string; data: PageFields | AssetFields }) => {
          return ref?.__typename === "ContentfulPages"
            ? constructPath(ref.data as PageFields)
            : ref.data && "file" in ref.data
            ? ref.data.file.url
            : "/";
        }
      ),
    [INLINES.ASSET_HYPERLINK]: inlinesAssetHyperlink
  }
});
