import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import Dropdown from "./TopMenuDropdown";
import Search from "../Search/Search";
import {
  MenuContainerFields,
  MenuDropdownContainerFields
} from "../../../../node/fragments/NodeMenuContainerFields";
import { MenuItemFields } from "../../../../node/fragments/NodeMenuItemFields";
import { slugifyTitle } from "wmk-tag";
import { colors } from "../../../ui/colors";

const Wrap = styled.div`
  background: ${colors.green.getHex()};
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 2rem;
  .menu-item-wrap,
  .menu-dropdown-wrap {
    padding: 0.4rem 1rem;
    font-size: 0.8rem;
    a {
      color: ${colors.white.getHex()};
      white-space: nowrap;
      position: relative;
      z-index: 200;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .flex-column > div {
    padding: 0 0.75vw;
  }
`;

const TopMenu = ({
  data,
  currDrop,
  updateCurrDrop,
  isSearchOpen,
  setIsSearchOpen
}: {
  data: MenuContainerFields;
  currDrop: string;
  updateCurrDrop: (drop: string) => void;
  isSearchOpen?: boolean;
  setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Wrap>
      {data.menuItems.map(
        (menuItem: MenuItemFields | MenuDropdownContainerFields, i: number) => {
          const type = menuItem.__typename;
          const titleSlug = slugifyTitle(menuItem.title);
          return type === "ContentfulMenuItem" ? (
            <MenuItem
              updateCurrDrop={updateCurrDrop}
              key={menuItem.title + i}
              slug={menuItem.entry.slug}>
              {menuItem.title}
            </MenuItem>
          ) : (
            <DropdownItem
              key={menuItem.title + i}
              menuItem={menuItem}
              dropdownTitleSlug={titleSlug}
              currDrop={currDrop}
              updateCurrDrop={updateCurrDrop}
            />
          );
        }
      )}
      <Search isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
    </Wrap>
  );
};

export default TopMenu;

const MenuItem = ({
  children,
  slug,
  updateCurrDrop
}: {
  children: React.ReactNode;
  slug: string;
  updateCurrDrop: (s: string) => void;
}) => {
  return (
    <div className="menu-item-wrap">
      <WmkLink
        onClick={() => updateCurrDrop("")}
        to={`/` + slug}
        style={{ textDecoration: "none" }}>
        {children}
      </WmkLink>
    </div>
  );
};

const DropdownItem = ({
  menuItem,
  dropdownTitleSlug,
  currDrop,
  updateCurrDrop
}: {
  menuItem: MenuDropdownContainerFields;
  dropdownTitleSlug: string;
  currDrop: string;
  updateCurrDrop: (s: string) => void;
}) => {
  return (
    <div
      className="menu-dropdown-wrap"
      style={menuItem.title === "Resources" ? { whiteSpace: "nowrap" } : {}}>
      <Dropdown
        menuItem={menuItem}
        dropdownTitleSlug={dropdownTitleSlug}
        currDrop={currDrop}
        updateCurrDrop={updateCurrDrop}
      />
    </div>
  );
};
