import * as React from "react";
import { Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import { LinkTarget } from "wmk-link";
import {
  ContentfulDocumentsEmbedFields,
  ContentfulDocumentsTableEmbedFields,
  ContentfulVideoEmbedFields
} from "../../node/fragments/NodePageFields";
import Accordion from "../../vars/richText/blockEntry/Entry/accordion";
import DocEmbed from "../../vars/richText/blockEntry/Entry/documentsEmbed/documentsEmbed";
import DocumentsTableEmbed from "../../vars/richText/blockEntry/Entry/documentsTableEmbed";
import Button from "../common/Button";

export interface ContentfulMenuItemFields {
  __typename: "ContentfulMenuItem";
  title: string;
  asset: {
    title: string;
    file: { url: string };
  } | null;
  target?: LinkTarget;
  entry: null | {
    __typename: string;
    slug: string;
    subParentPageSlug: string;
    parentPageSlug: string;
    title: string;
  };
  url: string | null;
}

interface ContentfulSimpleTextFields {
  __typename: "ContentfulSimpleText";
  title: string;
  simpleLongText: { simpleLongText: string };
}

interface ContentfulContentColumnsFields {
  title: string;
  __typename: "ContentfulContentColumns";
  content: (
    | ContentfulDocumentsEmbedFields
    | ContentfulSimpleTextFields
    | ContentfulMenuItemFields
  )[];
}

export interface ContentfulSimpleAccordion {
  contentful_id: "ContentfulSimpleAccordion";
  title: string;
  accordion: {
    raw: string;
    references: ContentfulVideoEmbedFields;
  };
}

export const constructPath = (pageQuery: {
  slug: string;
  subParentPageSlug: string | null;
  parentPageSlug: string | null;
}) => {
  return pageQuery
    ? `${pageQuery.parentPageSlug ? `/${pageQuery.parentPageSlug}` : ``}${
        pageQuery.subParentPageSlug ? `/${pageQuery.subParentPageSlug}` : ``
      }/${pageQuery.slug}`
    : "/";
};

export const BlockButton = (props: ContentfulMenuItemFields) => {
  const isAsset = props.asset && "file" in props.asset;
  const isLink = props.url;
  return (
    <div style={{ width: `100%`, textAlign: "center", padding: `1rem 0` }}>
      <Button
        to={
          isAsset
            ? props.asset.file.url
            : isLink
            ? props.url
            : constructPath(props.entry)
        }
        target={props.target}
        style={{ display: "inline-block" }}>
        {props.title}
      </Button>
    </div>
  );
};

export const BlockSimpleText = (props: ContentfulSimpleTextFields) =>
  props.simpleLongText ? (
    <div
      style={{
        width: `100%`,
        display: "flex",
        justifyContent: "center",
        padding: `1rem 0`
      }}>
      <ReactMarkdown>{props.simpleLongText?.simpleLongText}</ReactMarkdown>
    </div>
  ) : null;

export const blockHash = {
  ContentfulVideoEmbed: (props: ContentfulVideoEmbedFields) => {
    return (
      <div
        style={{
          width: `100%`,
          display: "flex",
          justifyContent: "center",
          padding: `1rem 0`
        }}>
        <ReactPlayer url={props.url} />
      </div>
    );
  },
  ContentfulSimpleAccordion: (props: ContentfulSimpleAccordion) => {
    return <Accordion {...props}/>;
  },
  ContentfulDocumentsEmbed: (props: ContentfulDocumentsEmbedFields) => (
    <DocEmbed fields={props} />
  ),
  ContentfulDocumentsTableEmbed: (
    props: ContentfulDocumentsTableEmbedFields
  ) => <DocumentsTableEmbed table={props} />,
  ContentfulSimpleText: (props: ContentfulSimpleTextFields) => (
    <BlockSimpleText {...props} />
  ),
  ContentfulMenuItem: (props: ContentfulMenuItemFields) => (
    <BlockButton {...props} />
  ),
  ContentfulContentColumns: (props: ContentfulContentColumnsFields) => {
    return (
      <Row style={{ width: `100%`, display: `flex`, justifyContent: "center" }}>
        {Array.isArray(props.content)
          ? props.content.map((c, i) => {
              return c.__typename === "ContentfulMenuItem" ? (
                <Col key={c.title + i} xs="auto">
                  <BlockButton {...c} />
                </Col>
              ) : c.__typename === "ContentfulSimpleText" ? (
                <Col xs="auto" key={c.title + i}>
                  <BlockSimpleText {...c} />
                </Col>
              ) : (
                <div key={c.__typename + i}>
                  <>{console.log(c.__typename)}</>
                </div>
              );
            })
          : null}
      </Row>
    );
  }
};
