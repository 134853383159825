import * as React from "react";
import CSS from "csstype";
import styled from "styled-components";
import { colors } from "./colors";

const Styled = {
  P: styled.div`
    display: inline;
    code {
      font-family: Assistant, sans-serif;
      color: ${colors.black.toString()};
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
    }
    a {
      color: ${colors.orange.getHex()};
      &:hover {
        color: ${colors.blue.getHex()};
      }
    }
  `
};

export const Typography = {
  H1: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: CSS.Properties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h1 style={style} ref={ref}>
        {children}
      </h1>
    )
  ),
  H2: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: CSS.Properties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h2 style={style} ref={ref}>
        {children}
      </h2>
    )
  ),
  H3: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: CSS.Properties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h3 style={style} ref={ref}>
        {children}
      </h3>
    )
  ),
  H4: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: CSS.Properties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h4 style={style} ref={ref}>
        {children}
      </h4>
    )
  ),
  H5: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: CSS.Properties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h5 style={style} ref={ref}>
        {children}
      </h5>
    )
  ),
  H6: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: CSS.Properties },
      ref: React.ForwardedRef<HTMLHeadingElement>
    ) => (
      <h6 style={style} ref={ref}>
        {children}
      </h6>
    )
  ),
  P: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: CSS.Properties },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <Styled.P style={style} ref={ref}>
        {children}
      </Styled.P>
    )
  ),
  BLOCKQUOTE: React.forwardRef(
    (
      {
        children,
        style
      }: { children: React.ReactNode; style?: CSS.Properties },
      ref: React.ForwardedRef<HTMLQuoteElement>
    ) => (
      <blockquote style={style} ref={ref}>
        {children}
      </blockquote>
    )
  )
};
