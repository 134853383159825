import React, { useState, useEffect } from "react";
import styled from "styled-components";
//import { Link } from 'gatsby'
import Theme, { buildSlug } from "../../../../vars/ThemeOptions";
import { FaAngleDown } from "react-icons/fa";
import { WmkLink } from "wmk-link";
import OffClick from "../.././../common/OffClick";
import { MenuItemFields } from "../../../../node/fragments/NodeMenuItemFields";
import { MenuDropdownContainerFields } from "../../../../node/fragments/NodeMenuContainerFields";

const Wrap = styled.div`
  position: relative;
  .drop-title {
    position: relative;
    z-index: 200;
    a.wmk-link {
      color: ${Theme.color.white.hex};
    }
    button {
      color: ${Theme.color.white.hex};
      background: ${Theme.color.green.hex};
      border: none;
      padding: 0 5px;
      // transition: transform .5s ease;

      // transform: rotate(0deg);
      // &.open {
      //   transform: rotate(180deg);
      //   transition: transform .5s ease;
      // }
      // &.closed {
      //   transform: rotate(0deg);
      //   transition: transform .5s ease;
      // }
    }
  }
  .drawer {
    right: 0;
    background: ${Theme.color.greenPointEightOp.rgba};
    position: absolute;
    z-index: -100;
    top: -20rem;
    opacity: 0;
    transition: all 0.3s ease;
    .drawer-item {
      padding: 5px 10px;
      a {
        color: ${Theme.color.white.hex};
        white-space: nowrap;
        position: relative;
        z-index: -100;
        font-size: 0.7rem;
        text-decoration: none;
      }
    }
  }
`;

const TopMenuDropdown = ({
  menuItem,
  dropdownTitleSlug,
  currDrop,
  updateCurrDrop
}: {
  menuItem: MenuDropdownContainerFields;
  dropdownTitleSlug: string;
  currDrop: string;
  updateCurrDrop: (s: string) => void;
}) => {
  // console.log("menuItem from TopMenuDropdown.js: ", menuItem);

  const [dropOpen, setDropOpen] = useState(false);
  useEffect(() => {
    currDrop === menuItem.title ? setDropOpen(true) : setDropOpen(false);
  }, [currDrop, menuItem.title]);

  return (
    <Wrap>
      <div className="drop-title">
        <button onClick={() => updateCurrDrop(menuItem.title)}>
          {menuItem.title}
        </button>
        <span>
          <button onClick={() => updateCurrDrop(menuItem.title)}>
            <FaAngleDown
              style={
                dropOpen
                  ? {
                      transform: "rotate(180deg)",
                      transition: "transform .5s ease"
                    }
                  : {
                      transform: "rotate(0deg)",
                      transition: "transform .5s ease"
                    }
              }
            />
          </button>
        </span>
      </div>
      <div
        className="drawer"
        style={dropOpen ? { zIndex: 101, top: "1.6rem", opacity: 1 } : null}>
        {menuItem.menuItems.map((item: MenuItemFields, i: number) => {
          const { title, entry } = item;
          return (
            <div className="drawer-item" key={title + i}>
              <WmkLink
                onClick={() => updateCurrDrop("")}
                to={buildSlug(entry)}
                style={dropOpen ? { zIndex: 10, opacity: 1 } : null}>
                {title}
              </WmkLink>
            </div>
          );
        })}
      </div>
      {currDrop !== "" && <OffClick closeAction={updateCurrDrop} />}
    </Wrap>
  );
};

export default TopMenuDropdown;
