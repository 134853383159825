exports.components = {
  "component---src-node-job-tsx": () => import("./../../../src/node/job.tsx" /* webpackChunkName: "component---src-node-job-tsx" */),
  "component---src-node-media-tag-tsx": () => import("./../../../src/node/mediaTag.tsx" /* webpackChunkName: "component---src-node-media-tag-tsx" */),
  "component---src-node-media-tsx": () => import("./../../../src/node/media.tsx" /* webpackChunkName: "component---src-node-media-tsx" */),
  "component---src-node-news-tsx": () => import("./../../../src/node/news.tsx" /* webpackChunkName: "component---src-node-news-tsx" */),
  "component---src-node-page-tsx": () => import("./../../../src/node/page.tsx" /* webpackChunkName: "component---src-node-page-tsx" */),
  "component---src-node-tag-tsx": () => import("./../../../src/node/tag.tsx" /* webpackChunkName: "component---src-node-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

