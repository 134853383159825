import * as React from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import styled from "styled-components";
import { RichText, RichTextReact } from "wmk-rich-text";
import { ContentfulSimpleAccordion } from "../../../../components/Blocks/BlockHash";
import { options } from "../../../../components/Blocks/richTextOptions";
import { colors } from "../../../../components/ui/colors";

const StyledCardHeader = styled(Card.Header)`
  color: ${colors.blue.getHex()};
  border-bottom: none !important;
  &:hover {
    cursor: pointer;
  }
  transition: all 0.3s ease;
`;

const AccordionComp = (props: ContentfulSimpleAccordion) => {
  const content = new RichText(props.accordion)
  return (
    <Accordion defaultActiveKey={"1"}>
      <Card>
        <Card.Header>
          {/* <Accordion.Toggle as={StyledCardHeader} variant="link" eventKey="0">
            {get(fields, `title[${locale}]`, "error")}
          </Accordion.Toggle> */}
          <CustomToggle eventKey="0">
            {props?.title}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <RichTextReact content={content} options={options}/>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

const CustomToggle = ({ children, eventKey }: {children: string, eventKey: string}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    setIsOpen(!isOpen)
  );

  return (
    <div style={{ position: "relative" }}>
      <StyledCardHeader
        type="button"
        style={{
          backgroundColor: isOpen
            ? "rgba(0, 0, 0, 0.03)"
            : `${colors.cyan.getRGB()}, 0.25`,
        }}
        onClick={decoratedOnClick}
      >
        {children}
      </StyledCardHeader>

      <FaAngleDown
        style={{
          position: "absolute",
          right: "2rem",
          top: `${51 / 3}px`,
          transform: isOpen ? `rotate(0deg)` : `rotate(180deg)`,
          transition: `all .3s ease`,
        }}
      />
    </div>
  );
}

export default AccordionComp